import { render, staticRenderFns } from "./BotStopper.vue?vue&type=template&id=57934c64&scoped=true&"
import script from "./BotStopper.vue?vue&type=script&lang=js&"
export * from "./BotStopper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57934c64",
  null
  
)

export default component.exports