import { render, staticRenderFns } from "./page_2.vue?vue&type=template&id=f386f3fa&scoped=true&"
import script from "./page_2.vue?vue&type=script&lang=js&"
export * from "./page_2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f386f3fa",
  null
  
)

export default component.exports