var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-center-matching-task","size":"xl","title":"Matching Task","hide-footer":true,"header-bg-variant":_vm.headerBgVariant,"header-text-variant":_vm.headerTextVariant,"body-bg-variant":_vm.bodyBgVariant,"body-text-variant":_vm.bodyTextVariant,"footer-bg-variant":_vm.footerBgVariant,"footer-text-variant":_vm.footerTextVariant,"no-close-on-backdrop":false,"no-close-on-esc":true,"hide-header-close":true,"modal-class":"b1style"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('b-container',{staticClass:"bv-example-row",style:(this.windowsize)},[_c('b-row',{staticClass:"justify-content-center align-items-center my-1"},[_c('b-progress',{staticStyle:{"width":"100%"},attrs:{"value":this.current_progress,"max":this.max_avatar,"show-progress":"","animated":""}})],1),_c('b-row',{staticClass:"justify-content-center align-items-center my-1",style:(this.main_images_style)},[_c('img',{style:(this.global_size),attrs:{"src":require('../assets/2AFC/background.png')}}),_c('img',{style:(this.remind_style),attrs:{"src":require("../assets/2AFC/press_remind.png")}}),_c('img',{style:(this.feedbackA_style),attrs:{"src":require("../assets/2AFC/feedbackA.png")}}),_c('img',{style:(this.feedbackD_style),attrs:{"src":require("../assets/2AFC/feedbackD.png")}}),_c('img',{style:(this.avatar_left_style),attrs:{"src":require(("../assets/Avatars/Sighted Avatars/av" + (this.pad(
            this.combinations[this.current_avatar].avatar_idL, 3)) + ".png"))}}),_c('img',{style:(this.avatar_rght_style),attrs:{"src":require(("../assets/Avatars/Sighted Avatars/av" + (this.pad(
            this.combinations[this.current_avatar].avatar_idR, 3)) + ".png"))}}),_c('img',{style:(this.dots_main_style),attrs:{"src":require(("../assets/2AFC/dots/dots_ltb" + (this.combinations[
            this.current_avatar].temp_leftAs) + ".png"))}}),_c('img',{style:(this.dots_main_style),attrs:{"src":require(("../assets/2AFC/dots/dots_ltp" + (this.combinations[
            this.current_avatar].temp_leftAo) + ".png"))}}),_c('img',{style:(this.dots_main_style),attrs:{"src":require(("../assets/2AFC/dots/dots_lbb" + (this.combinations[
            this.current_avatar].temp_leftBs) + ".png"))}}),_c('img',{style:(this.dots_main_style),attrs:{"src":require(("../assets/2AFC/dots/dots_lbp" + (this.combinations[
            this.current_avatar].temp_leftBo) + ".png"))}}),_c('img',{style:(this.dots_main_style),attrs:{"src":require(("../assets/2AFC/dots/dots_rtb" + (this.combinations[
            this.current_avatar].temp_rghtAs) + ".png"))}}),_c('img',{style:(this.dots_main_style),attrs:{"src":require(("../assets/2AFC/dots/dots_rtp" + (this.combinations[
            this.current_avatar].temp_rghtAo) + ".png"))}}),_c('img',{style:(this.dots_main_style),attrs:{"src":require(("../assets/2AFC/dots/dots_rbb" + (this.combinations[
            this.current_avatar].temp_rghtBs) + ".png"))}}),_c('img',{style:(this.dots_main_style),attrs:{"src":require(("../assets/2AFC/dots/dots_rbp" + (this.combinations[
            this.current_avatar].temp_rghtBo) + ".png"))}}),_c('img',{style:(this.dots_mask_style),attrs:{"src":require(("../assets/2AFC/dots/dots_mtb" + (this.combinations[
            this.current_avatar].temp_maskAs) + ".png"))}}),_c('img',{style:(this.dots_mask_style),attrs:{"src":require(("../assets/2AFC/dots/dots_mtp" + (this.combinations[
            this.current_avatar].temp_maskAo) + ".png"))}}),_c('img',{style:(this.dots_mask_style),attrs:{"src":require(("../assets/2AFC/dots/dots_mbb" + (this.combinations[
            this.current_avatar].temp_maskBs) + ".png"))}}),_c('img',{style:(this.dots_mask_style),attrs:{"src":require(("../assets/2AFC/dots/dots_mbp" + (this.combinations[
            this.current_avatar].temp_maskBo) + ".png"))}}),_c('img',{style:(this.choiceup_main_style),attrs:{"src":require("../assets/2AFC/choicelt.png")}}),_c('img',{style:(this.choicedn_main_style),attrs:{"src":require("../assets/2AFC/choicelb.png")}}),_c('img',{style:(this.choiceup_main_style),attrs:{"src":require("../assets/2AFC/choicert.png")}}),_c('img',{style:(this.choicedn_main_style),attrs:{"src":require("../assets/2AFC/choicerb.png")}}),_c('img',{style:(this.choiceup_mask_style),attrs:{"src":require("../assets/2AFC/choicemt.png")}}),_c('img',{style:(this.choicedn_mask_style),attrs:{"src":require("../assets/2AFC/choicemb.png")}}),_c('img',{style:(this.promptsAD_style),attrs:{"src":require("../assets/2AFC/promptA.png")}}),_c('img',{style:(this.promptsAD_style),attrs:{"src":require("../assets/2AFC/promptD.png")}})])],1),_c('b-modal',{ref:"my-rest-break-modal",attrs:{"hide-footer":"","title":"Rest Break"}},[_c('h3',{attrs:{"align":"center"}},[_vm._v("0"+_vm._s(this.rest_break_min)+":"+_vm._s(this.rest_break_seczero)+_vm._s(this.rest_break_sec))]),_c('b-button',{staticClass:"mt-3",attrs:{"variant":"outline-danger","block":""},on:{"click":_vm.hideRestBreakModal}},[_vm._v("Back to Experiment")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }